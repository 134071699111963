<template>
  <b-container>
    <HPageTitle>
      {{ $t('profile.myAccount') }}
    </HPageTitle>
    <ProfilCard />
  </b-container>
</template>

<script>
import ProfilCard from '@/components/ProfilCard'
export default {
  components: {
    ProfilCard,
  },
}
</script>

<style lang="scss" scoped></style>
